<script lang="ts">
	import BenefitCard from '../Card/BenefitCard.svelte';

	export let benefits: Array<{ title: string; description: string; icon: string }> = [];
</script>

<div class="flex flex-col md:flex-row justify-between gap-12">
	{#each benefits as benefit}
		<BenefitCard title={benefit.title} description={benefit.description} icon={benefit.icon} />
	{/each}
</div>
