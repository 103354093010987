<script lang="ts">
	import { classes } from '$lib/utils';
	export let additionalClass = '';
</script>

<svg
	width="38"
	height="38"
	viewBox="0 0 38 38"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	class={classes('w-12 h-12', additionalClass)}
>
	<path
		d="M29 9H33C34.0609 9 35.0783 9.42143 35.8284 10.1716C36.5786 10.9217 37 11.9391 37 13V25C37 26.0609 36.5786 27.0783 35.8284 27.8284C35.0783 28.5786 34.0609 29 33 29H29V37L21 29H13C12.4747 29.0005 11.9544 28.8973 11.4691 28.6962C10.9837 28.495 10.543 28.2 10.172 27.828M10.172 27.828L17 21H25C26.0609 21 27.0783 20.5786 27.8284 19.8284C28.5786 19.0783 29 18.0609 29 17V5C29 3.93913 28.5786 2.92172 27.8284 2.17157C27.0783 1.42143 26.0609 1 25 1H5C3.93913 1 2.92172 1.42143 2.17157 2.17157C1.42143 2.92172 1 3.93913 1 5V17C1 18.0609 1.42143 19.0783 2.17157 19.8284C2.92172 20.5786 3.93913 21 5 21H9V29L10.172 27.828Z"
		stroke="#111928"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
