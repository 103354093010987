<script lang="ts">
	import { classes } from '$lib/utils';
	export let additionalClass = '';
</script>

<svg
	height="100%"
	width="100%"
	version="1.1"
	viewBox="0 0 48 48"
	fill="none"
	stroke="currentColor"
	xmlns="http://www.w3.org/2000/svg"
	class={classes('w-12 h-12', additionalClass)}
	><use xlink:href="#ytp-id-50" />
	<path
		d="M10 6V14V6ZM6 10H14H6ZM12 34V42V34ZM8 38H16H8ZM26 6L30.572 19.714L42 24L30.572 28.286L26 42L21.428 28.286L10 24L21.428 19.714L26 6Z"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
