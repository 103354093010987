<script lang="ts">
	import BenefitsBar from '$lib/components/Bar/BenefitsBar.svelte';
	import { classes } from '$lib/utils';
	import { getTranslate } from '@tolgee/svelte';

	/**
	 * tolgeePrefix
	 * Das ist der prefix für die tolgee keys, die in dieser component verwendet werden.
	 * Dadurch können wir diese Component auf mehreren Pages mit unterschiedlichem Content füllen oder mit demselben – wenn wir wollen. :)
	 **/
	export let tolgeePrefix: string;
	export let additionalClass = '';
	export let journeyItems: Array<{ title: string; description: string; icon: string }> = [];

	const { t } = getTranslate();
</script>

{#if journeyItems.length > 0}
	<section class={classes('bg-white', additionalClass)}>
		<div>
			<h2 class="mb-4 text-4xl font-bold uppercase">
				{@html $t(`${tolgeePrefix}.journey-section.title`)}
			</h2>
			<h3 class="text-lg font-normal mb-14">
				{@html $t(`${tolgeePrefix}.journey-section.subline`)}
			</h3>
		</div>
		<BenefitsBar benefits={journeyItems} />
	</section>
{/if}
