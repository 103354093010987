<script lang="ts">
	import HandIcon from './../Icon/HandIcon.svelte';
	import SpeachBubbleIcon from './../Icon/SpeachBubbleIcon.svelte';
	import SparklesIcon from './../Icon/SparklesIcon.svelte';

	export let title: string;
	export let description: string;
	export let icon: string;

	// TODO: Better typing here if possible - ask Nic for suggestion :P
	const iconComponents: any = {
		SparklesIcon,
		SpeachBubbleIcon,
		HandIcon
	};
</script>

<div class="flex flex-col">
	<svelte:component this={iconComponents[icon]} />
	<div class="text-xl font-bold mt-4">{title}</div>
	<div class="text-lg font-normal mt-2">{description}</div>
</div>
