<script lang="ts">
	import { classes } from '$lib/utils';
	export let additionalClass = '';
</script>

<svg
	width="100%"
	height="100%"
	viewBox="0 0 32 38"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	class={classes('w-[30px] h-12', additionalClass)}
>
	<path
		d="M7 18V23M7 18V6C7 5.20435 7.31607 4.44129 7.87868 3.87868C8.44129 3.31607 9.20435 3 10 3C10.7956 3 11.5587 3.31607 12.1213 3.87868C12.6839 4.44129 13 5.20435 13 6M7 18C7 17.2044 6.68393 16.4413 6.12132 15.8787C5.55871 15.3161 4.79565 15 4 15C3.20435 15 2.44129 15.3161 1.87868 15.8787C1.31607 16.4413 1 17.2044 1 18V22C1 25.9782 2.58035 29.7936 5.3934 32.6066C8.20644 35.4196 12.0218 37 16 37C19.9782 37 23.7936 35.4196 26.6066 32.6066C29.4196 29.7936 31 25.9782 31 22V12C31 11.2044 30.6839 10.4413 30.1213 9.87868C29.5587 9.31607 28.7956 9 28 9C27.2044 9 26.4413 9.31607 25.8787 9.87868C25.3161 10.4413 25 11.2044 25 12M13 6V17M13 6V4C13 3.20435 13.3161 2.44129 13.8787 1.87868C14.4413 1.31607 15.2044 1 16 1C16.7956 1 17.5587 1.31607 18.1213 1.87868C18.6839 2.44129 19 3.20435 19 4V6M25 12V6C25 5.20435 24.6839 4.44129 24.1213 3.87868C23.5587 3.31607 22.7956 3 22 3C21.2044 3 20.4413 3.31607 19.8787 3.87868C19.3161 4.44129 19 5.20435 19 6M25 12V17M19 6V17"
		stroke="#111928"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
